::-webkit-scrollbar {
    display: none;
}
.test {
  background-color: white;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-top: 20px;
}

.table-title {
  color: black;
  font-weight: bold;
}
